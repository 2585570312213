import styled from 'src/style';
import colors from 'src/style/colors';
import fonts from 'src/style/fonts';
import { mediaQueries } from 'src/style/breakpoints';

//Form Section

export const SuccessBlockContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  margin: 'auto 0',
});

// Error Message
export const Oval = styled.div({
  backgroundColor: colors.yellow,
  height: 11,
  width: 11,
  borderRadius: '50%',
  marginTop: 5,
});

export const Wrapper = styled.div({
  height: 240,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// Form Steps

export const FormStepsContainer = styled.div({
  ...fonts.HKCompakt.bold,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 85,
  padding: '0px 30px',
  backgroundColor: colors.lightGrey,
  [mediaQueries.phoneOnly]: {
    display: 'none',
  },
  [mediaQueries.tabletLandscapeUp]: {
    padding: '0px 60px',
  },
});

export const FormContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

// MultiSelect

export const MultiSelectLargeContainer = styled.div(
  {
    flex: 1,
    textAlign: 'center',
    borderRadius: 3,
    transitionProperty: 'all',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
    position: 'relative',
    [mediaQueries.phoneOnly]: {
      flex: 'none',
      width: '100%',
    },
  },
  ({ isSelected }) => ({
    border: `1px solid ${isSelected ? colors.purple : '#C0C0C0'}`,
    color: isSelected ? colors.purple : '#C0C0C0',
  }),
  ({ isFull }) => ({
    backgroundColor: isFull ? colors.midGrey : colors.white,
    cursor: !isFull && 'pointer',
    pointerEvents: isFull && 'none',
    '&:hover': {
      border: !isFull && `1px solid ${colors.purple}`,
      color: !isFull && colors.purple,
      backgroundColor: !isFull && colors.transparentPurple,
    },
  }),
  ({ modal }) => ({
    padding: modal ? '20px 8px' : '26px 8px',
    display: modal && 'flex',
    justifyContent: modal && 'center',
    alignItems: modal && 'center',
  })
);

// Perks

export const PerksContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 20,
  width: 300,
});

export const PerksWrapper = styled.div({
  display: 'none',
  paddingLeft: 0,
  [mediaQueries.desktopUp]: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '340px',
  },
  [mediaQueries.phoneOnly]: {
    display: 'none',
  },
});
