import React from 'react';
import { MultiSelectLargeContainer } from './elements';
import fonts from 'src/style/fonts';

const MultiSelectLarge = ({
  className,
  isSelected = false,
  onClick,
  title,
  title2,
  subtitle,
  subtitle2,
  subtitle3,
  isFull,
  errors,
  modal,
}) => (
  <MultiSelectLargeContainer
    className={className}
    isSelected={isSelected}
    onClick={onClick}
    isFull={isFull}
    errors={errors}
    modal={modal}
  >
    <div
      css={{
        ...fonts.HKCompakt.bold,
        paddingBottom: subtitle3 ? 8 : subtitle || subtitle2 ? 4 : 0,
      }}
    >
      {title}
      <br />
      {title2 ? <div>{title2}</div> : null}
    </div>
    {subtitle && (
      <div
        css={{
          ...fonts.HKCompakt.medium,
          paddingBottom:
            (title === 'Temps Partiel' || title === 'Temps Plein' || title === 'Alternance') &&
            subtitle
              ? 2
              : 8,
          fontSize: 15,
        }}
      >
        {subtitle}
      </div>
    )}
    {subtitle2 && <div css={{ ...fonts.HKCompakt.medium, fontSize: 15 }}>{subtitle2}</div>}
    {subtitle3 && (
      <div css={{ ...fonts.HKCompakt.medium, fontSize: 15, fontWeight: 'bold' }}>{subtitle3}</div>
    )}
  </MultiSelectLargeContainer>
);

export default MultiSelectLarge;
