import React, { useState } from 'react';
import Layout from '../components/Layout';
import QA from '../data/QuestionsAnswers';
import { Section } from '../components/Section';
import { TopbarStaticSpace } from '../components/Topbar';
import { QAItem, CTAFooter, FAQWhiteBG } from '../components/QuestionsAnswer/index';
import { animatedScrollTo } from '../utils/animatedScrollTo';
import { mediaQueries } from 'src/style/breakpoints';
import { Easing } from '../utils/Easings';
import sizes from 'src/style/sizes';

import { StickyBoxFAQ } from '../components/FAQ';
import MultiSelectLarge from 'src/components/ApplicationForm/MultiSelectLarge';
import { Label } from 'src/components/Form/elements';

const FAQ = ({ location }) => {
  const [openedItem, setOpenedItem] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(() => {
    if (location.state && location.state.fromFormationsPage) {
      if (location.state.fromFormationsPage === 'E-Learning') {
        return 'RNCP5';
      } else {
        return 'RNCP6';
      }
    } else {
      return null;
    }
  });
  const container = React.useRef();

  const QAfiltered = QA.filter((item) => {
    if (selectedCertification && item.courses.indexOf(selectedCertification) !== -1) {
      return item;
    } else return null;
  });

  const QAMap = QA.map((item) => ({
    '@type': 'Question',
    name: item.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: item.answer,
    },
  }));

  React.useEffect(() => {
    if (openedItem !== null && container.current) {
      const itemPos = container.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const target = windowHeight + 40;

      const bottomDist = target - itemPos.bottom;

      if (bottomDist < 0) {
        const scrollDest = window.scrollY + Math.abs(bottomDist);
        const actions = animatedScrollTo(window, scrollDest, {
          duration: 300,
          easing: Easing.easeInOutQuart,
        });

        const onWheel = () => {
          actions.stop();
        };

        window.addEventListener('wheel', onWheel);

        return () => {
          actions.stop();
          window.removeEventListener('wheel', onWheel);
        };
      }
    }
  });

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName="FAQ"
      pageType="page"
      metaTitle="Questions fréquentes | Le Reacteur"
      metaDescription="Retrouvez plein de questions qui nous ont été posées avant d'intégrer notre formation de développeur Web et Mobile."
    >
      <Section theme="primary" css={{ height: sizes.purpleBgHeightDesktop }} />

      <TopbarStaticSpace />
      <Section theme="secondary">
        <FAQWhiteBG marginTop={sizes.marginTopContainer} theme="light">
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              [mediaQueries.tabletLandscapeUp]: {
                flexDirection: 'row',
                alignItems: 'flex-start',
              },
            }}
          >
            <StickyBoxFAQ />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 50,
                width: '100%',
                [mediaQueries.tabletLandscapeUp]: {
                  paddingLeft: 100,
                },
              }}
            >
              <section css={{ display: 'flex', flexDirection: 'column' }}>
                <Label>Quelle formation vous intéresse ?</Label>
                <div
                  css={{
                    display: 'flex',
                    gap: 10,
                    flexDirection: 'column',
                    [mediaQueries.tabletPortraitUp]: {
                      flexDirection: 'row',
                    },
                  }}
                >
                  <MultiSelectLarge
                    title="Développeur Web et Web Mobile"
                    subtitle="RNCP5 - Niveau Bac+2"
                    subtitle3="en e-learning + mentoring"
                    isSelected={selectedCertification === 'RNCP5'}
                    onClick={() => {
                      if (selectedCertification !== 'RNCP5') {
                        setSelectedCertification('RNCP5');
                        setOpenedItem(null);
                      }
                    }}
                  />
                  <MultiSelectLarge
                    title="Développeur Web et Mobile"
                    subtitle="RNCP6 - Niveau Bac+3/4"
                    subtitle3={`en\u00A0temps\u00A0plein, en\u00A0temps\u00A0partiel ou en\u00A0alternance`}
                    isSelected={selectedCertification === 'RNCP6'}
                    onClick={() => {
                      if (selectedCertification !== 'RNCP6') {
                        setSelectedCertification('RNCP6');
                        setOpenedItem(null);
                      }
                    }}
                  />
                </div>
              </section>
              {selectedCertification && (
                <section
                  css={{
                    paddingTop: 50,
                    width: '100%',
                    marginBottom: '-80px',
                  }}
                >
                  {QAfiltered.map((item, index) => (
                    <QAItem
                      key={item.question}
                      question={item.question}
                      answer={item.answer}
                      isOpen={openedItem === index}
                      onQuestionClick={() => {
                        setOpenedItem((prev) => (prev === index ? null : index));
                      }}
                      containerRef={openedItem === index ? container : undefined}
                    />
                  ))}
                </section>
              )}
            </div>
          </div>
        </FAQWhiteBG>
      </Section>
      <CTAFooter />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: QAMap,
          }),
        }}
      />
    </Layout>
  );
};

export default FAQ;
